import { Text, Flex, ListItem, UnorderedList, VStack } from "@chakra-ui/react"
import { FinanceBody } from '../data/SiteData'


const FinanceOptions = () => {

    return (
    <Flex id='FinanceAreaText' width='100%' px={['1rem', null, '10rem']}>
        <VStack alignItems={'flex-start'} spacing={0}>
            <Text fontWeight={[600, null, 500]} fontSize={[20, null, 40]} mb={0}>{FinanceBody.InfoTitle}</Text>
            <Text fontSize={[15, null, 20]}>{FinanceBody.InfoSubTitle}</Text>
            <UnorderedList 
                pl={['0.8rem', null, '2rem']} 
                mb={0} 
                spacing={[2, null, 3]} 
                fontSize={[15, null, 20]}
                marginInlineStart={['0.5rem', null, '1rem']}
            >
                {FinanceBody.FinanceOptions.map((x) => <ListItem>{x}</ListItem>)}
            </UnorderedList>
        </VStack>
    </Flex>
    )
}

export default FinanceOptions