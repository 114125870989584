import MainPage from './Components/MainPage'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ChakraProvider } from "@chakra-ui/react";

function App(){
    return(
        <ChakraProvider>
            <MainPage/>
        </ChakraProvider>
    )
}

export default App;
