import sinkImage from '../media/sink-counter-top-cropped.png'
import woodFlooring from '../media/wood-flooring-cropped.png'
import bathroom from '../media/bathroom-remodeling-cropped.png'
import { TileInformation } from './data'
import { FinanceInformation } from './data'

export const siteName : string = "WCR Enterprise";
export const serviceOptions: Array<string> = [
    "QUOTES / ESTIMATION",
    "COMPANY INQUIRIES",
    "GENERAL QUESTIONS",
    "NEW HOME BUILD",
    "REMODLING / REPAIR",
    "COMMERCIAL REMODLING / REPAIR",
    "CUSTOMER SERVICE SUPPORT"
]


export const FinanceBody : FinanceInformation = {
    InfoTitle : 'Financing Solutions',
    InfoSubTitle : 'Get your project started right away with our Financing Solutions!',
    FinanceOptions : [
        '12 months same as cash program. This program offers NO interest for 12 months, NO hidden fees, NO monthly payments. You can start your project right away with one call. Longer payment plans are available as well. Call for details 770-615-3882',
        "Home equity lines or 2nd mortgages. If you prefer to finance your project with a home equity line, you may be eligible for additional tax benefits. Call your local lender and accountant for more details. A big advantage of a home equity line of credit is it functions like a check book, once you pay it off you still have the money available if you need it without having to go through the approval process and pay for appraisal fees and other closing costs. If you need help securing a 2nd mortgage, we can steer you in the right direction.",
        "Refinance 1st mortgage. Rates on 1st mortgages are generally lower than those on home equity lines of credit or 2nd mortgages. Other advantages of getting a 1st mortgage over a 2nd are fixed interest rates and longer terms. Keep in mind that refinancing generally takes longer than obtaining a 2nd mortgage. Again if you need help finding a good lender give us a call."
    ]
};

export const TilesData : Array<TileInformation> = [
    {
        image_src : sinkImage,
        infoTextHeading : 'Luxury Remodeling',
        infoTextBody : "90% of our work is Design-Build, the delivery method that keeps everyone under the same roof. " + 
        "Single-source responsibility means you work with a skilled team that brings innovative solutions and added value to every phase of the job."
    },
    {
        image_src : woodFlooring,
        infoTextHeading : 'Professional Design',
        infoTextBody : "Our Interior designers make indoor spaces functional, safe, and " + 
        "beautiful by determining space requirements and selecting essential and decorative items, such as colors, lighting, and materials. "
    },
    {
        image_src : bathroom,
        infoTextHeading : 'Clear Communication',
        infoTextBody : "We pride ourselves on our great customer service, high standards and a diligent attention to detail. " + 
        "Today we offer a wide variety of home improvement services in Atlanta and surrounding areas. Offering all metropolitan Atlanta interior painting," + 
        " exterior painting, remodeling, roof repairs, gutter installation and repairs , deck building, deck painting, window replacements, siding replacements," + 
        " insulation and flooring; our first goal is to fulfill our client’s needs and expectations while at the same time adjusting to the budget."
    }
];