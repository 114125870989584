import Modal from 'react-bootstrap/Modal';


interface ThankYouProps {
    showpage: boolean
    handleClose : any
}

function ThankYou(props: ThankYouProps) {
    return (
        <>
            <Modal show={props.showpage} onHide={props.handleClose} size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>Thank You!</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    We will review your reqeust and contact you soon.
                </Modal.Body>

            </Modal>
        </>
    );
}

export default ThankYou