import './MainPage.css'
import { useState } from 'react'
import { siteName } from '../data/SiteData'

import Container from 'react-bootstrap/Container'
import { Element } from 'react-scroll'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import { TilesData, FinanceBody } from '../data/SiteData'
import InfoTile from './InfoTile/InfoTile'
import WCRNavBar, { SiteLogo } from './NaveBar'
import WCRFooter from './Footer'
import FinanceOptions from './FinanceOptions'
import HouseBanner from './HouseBanner'
import ContactUs from './ContactUs'
import { CustomerInfo } from '../data/data'
import { submit_to_email_sender } from '../utils';
import ThankYou from './ThankYou'
import { Grid, GridItem, Text, Button } from '@chakra-ui/react'
import { useMediaQuery, useBreakpointValue } from '@chakra-ui/react'

const screenSizes = {
    base: 'Base',
    sm:   'Small',
    md:   'Medium',
    lg:   'Large',
    "2xl" : 'HDDisplay'
  }


const MainPage = () => {

    const [showContactUs, setContactUs] = useState(false)
    const [showThankYou, setThankyou] = useState(false)
    const [customerInfo, setCustomerInfo]  = useState(null)
    const screenSize = useBreakpointValue(screenSizes)

    const handleSubmissionResponse = (event: any) => {
        console.log(event)
    }

    const toggleContactModal = () => {
        setContactUs(!showContactUs)
    }

    const toggleThankYouModal = () => {
        setThankyou(!showThankYou)
    }

    const handleContactSubmit = (info : CustomerInfo) => {
        setContactUs(false)
        setThankyou(true)
        handleSubmissionResponse(true)
        submit_to_email_sender(info, handleSubmissionResponse)
    }

    return (
        <Grid
            templateColumns={[
                '1fr 1fr',
                null,
                '14rem 1fr 8rem'
            ]}
            templateRows={'repeat(8, auto)'}
            mx={['0.7rem', null, '2rem']}
            gap={[2, null, 0]}
        >
            <GridItem>
                <SiteLogo />
            </GridItem>
            <GridItem colSpan={2} colStart={2} rowStart={1}>
            <Text mb={0} pt="25px" fontSize={['24px', null, '30px', '40px']}
                fontFamily="Times New Roman">WCR Enterprise</Text>
            </GridItem>
            <GridItem colStart={3} rowStart={1} colSpan={2} display={['None', null, 'flex']} justifyContent={'flex-end'}>
                <WCRNavBar handleOpenContactUs={toggleContactModal}/>
            </GridItem>
            <GridItem colSpan={4} display={['None', 'None', 'flex']}>
                <HouseBanner/>
            </GridItem>

            <GridItem colSpan={4} display={['flex', 'flex', 'None']} justifyContent={'center'}>
                <Button colorScheme='teal' variant='ghost' onClick={toggleContactModal}>
                    Contact Us!
                </Button>
            </GridItem>

            {
                TilesData.map((x, i) => {
                    return (
                        <GridItem 
                            py={['0.5rem', null, '3rem']}
                            colStart={1}
                            colSpan={[2, null, 4]} 
                            backgroundColor={(i % 2 === 0) ? 'white' : "rgb(255, 238, 186)"}
                            pr={['1rem', null, null, '8rem']}
                        >
                            <InfoTile
                                key={x.infoTextHeading}
                                image_src={x.image_src}
                                infoTextHeading={x.infoTextHeading}
                                infoTextBody={x.infoTextBody}
                                backgroundColor='none'
                            />
                        </GridItem>
                    )
                })
            }

            <GridItem id='FinanceOptions' 
                colSpan={4} 
                backgroundColor="rgb(255, 238, 186)" 
                py={['1rem', null, '3rem']}>
                <FinanceOptions/>
            </GridItem>

            <GridItem colSpan={4} display={['flex', 'flex', 'None']} justifyContent={'center'} pt='1rem'>
                <Button colorScheme='teal' variant='ghost' onClick={toggleContactModal}>
                    Request a Quote!
                </Button>
            </GridItem>

            <GridItem colSpan={4} pt={['1rem', null, '3rem']}>
                <WCRFooter/>
            </GridItem>
            <ContactUs
                showpage={showContactUs} 
                handleClose={toggleContactModal} 
                handleSubmit={handleContactSubmit} 
            />
            <ThankYou 
                showpage={showThankYou} 
                handleClose={toggleThankYouModal}
            />
        </Grid>
    );
}

export default MainPage