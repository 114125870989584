import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Figure from 'react-bootstrap/Figure'
import siteLogo2 from '../media/WCR-Enterprise-Logo-Transparent.png'
import { Link } from 'react-scroll'
import { Flex, Image } from '@chakra-ui/react';
import { MouseEventHandler } from 'react';

export const SiteLogo = () => {
    return (
        <Image
            width={[150, null, 200]}
            height={[100, null, 120]}
            alt="171x180"
            src={siteLogo2}
            py={['0.8rem', null, '1rem']}
            pl={['0.8rem', null, '1rem']}
            // style={{ padding: 10, paddingLeft: 20, paddingTop: 20 }}
        />
    )
}

// function WCRNavBar(props: any) {
//   return (
//     <Navbar collapseOnSelect expand="md" bg="light-blue text-bold" variant="light" className='d-flex justify-content-center align-items-center theme-align'>
//         <SiteLogo/>
//         <Container fluid className='d-flex justify-content-center align-items-center'>
//             <Navbar.Brand href="#home">WCR Enterprise Inc</Navbar.Brand>
//             <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//             <Navbar.Collapse id="responsive-navbar-nav">
//             <Nav className="me-auto">
//                 <Nav.Link href="#About-Us">About Us</Nav.Link>
//                 <Nav.Link><Link activeClass="active" spy to="LinkFinanceSection">Finance</Link></Nav.Link>
//                 {/* <Nav.Link href="#Referral Progam">Referral Progam</Nav.Link> */}
//                 <Nav.Link href="#Contact" onClick={props.handleOpenContactUs}>Contact Us</Nav.Link>
//             </Nav>
//             </Navbar.Collapse>
//         </Container>
//     </Navbar>
//   );
// }

interface WCRNavBarProps {
    handleOpenContactUs : MouseEventHandler<HTMLElement>
}

const WCRNavBar = (props: WCRNavBarProps) => {
    return (
        <Flex justifyContent={'flex-end'} fontSize={'20px'}>
            <Navbar collapseOnSelect expand="md" bg="light-blue text-bold" variant="light" className='d-flex justify-content-center align-items-center theme-align'>
                <Nav className="ml-auto">
                    <Nav.Link href='#FinanceOptions' color='black'>
                        <p>Finance Options</p>
                    </Nav.Link>
                    <Nav.Link onClick={props.handleOpenContactUs} color='black'>
                        <p>Get a Quote</p>
                    </Nav.Link>
                </Nav>
            </Navbar>
        </Flex>
    );
}

export default WCRNavBar;
