
import Card from 'react-bootstrap/Card';
import luxuryHouse from '../media/luxury-house.jpg'



const HouseBanner = () => {
    return (
        <>
            <Card className="m-0 p-0 text-white theme-font">
                <Card.Img src={luxuryHouse} alt="Card image" />
                <Card.ImgOverlay>
                    <Card.Text>
                    </Card.Text>
                </Card.ImgOverlay>
            </Card>
        </>
    )
}

export default HouseBanner